import type { ThemeIcon } from '~/type/config'

const { theme, themeVars } = useTheme()
const { getS3Domain } = useConstConfig()

export const getResource = (key = '') => {
  return themeVars.value.find((item) => item.themeKey === key)?.themeValue || ''
}

export function getIcon(key: string): ThemeIcon {
  // return {
  //   light: {
  //     selected: getResource(`${key}_selected_image_light`),
  //     unselected: getResource(`${key}_unselected_image_light`)
  //   },
  //   dark: {
  //     selected: getResource(`${key}_selected_image_night`),
  //     unselected: getResource(`${key}_unselected_image_night`)
  //   }
  // }
  return {
    light: {
      selected: getImage(`${key}_selected`),
      unselected: getImage(`${key}_unselected`)
    },
    dark: {
      selected: getImage(`${key}_selected`),
      unselected: getImage(`${key}_unselected`)
    }
  }
}

export function getCurrentIcon(icon: ThemeIcon = {}, active: boolean) {
  if (theme.value === 'light') {
    return active ? icon.light?.selected : icon.light?.unselected
  } else {
    return active ? icon.dark?.selected : icon.dark?.unselected
  }
}

export function getImage(key?: string) {
  // const theme = useState<string>('theme')
  // return getResource(theme.value === 'dark' ? `${key}_image_night` : `${key}_image_light`) ?? ''
  if (!key) return ''
  return getResource(key + '_image')
}

export function addHttpPrefix(image?: string) {
  if (!image) return undefined
  if (image?.startsWith('http') || image?.startsWith('//')) return image

  return `${getS3Domain()}/${image}`
    .replaceAll('//', '/')
    .replaceAll('http:/', '//')
    .replaceAll('https:/', '//')
}

export function hexToRgba(hex: string | undefined, alpha: number) {
  if (!hex) return ''
  // 检查十六进制颜色是否以 # 开头
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }
  // 解析红色、绿色、蓝色值
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  // 返回 RGBA 格式
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16)
  return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255]
}

export function linerColorInterp(
  startColor: string,
  endColor: string,
  progress: number
) {
  const startRgb = hexToRgb(startColor)
  const endRgb = hexToRgb(endColor)
  const currentRgb = startRgb.map((start, i) =>
    Math.round(start + (endRgb[i] - start) * progress)
  )
  return `rgb(${currentRgb[0]}, ${currentRgb[1]}, ${currentRgb[2]})`
}
