import { searchMyCustomerList } from '~/api/messageCenter'

export const useCustomer = () => {
  const customerLink = ref<string>('')
  const chatUrlType = ref<number>()
  const normalizeUrl = (url: string) => {
    // 如果url为空直接返回
    if (!url) return url
    // 正则判断是否以 http:// 或 https:// 开头
    const urlPattern = /^(http:\/\/|https:\/\/)/i

    if (!urlPattern.test(url)) {
      // 如果不以 http:// 或 https:// 开头，补充 https://
      return `https://${url}`
    }

    return url
  }
  const getCustomerLink = (type: number) => {
    if (type === 2) {
      // 普通客服直接跳客服列表
      customerLink.value = `/messageCenter?customerType=2&from=${location.href}`
      return
    }
    searchMyCustomerList({ model: { customerType: type } }).then((res) => {
      const backLink = res.length > 0 ? res[0]?.chatUrl ?? '' : ''
      customerLink.value = normalizeUrl(backLink)
      chatUrlType.value = res.length > 0 ? res[0]?.chatUrlType ?? 1 : 1
    })
  }

  return {
    chatUrlType,
    customerLink,
    getCustomerLink
  }
}
